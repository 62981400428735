import Section from 'components/Section'
import SectionContainer from 'components/Section/SectionContainer'
import styles from './ProductDetailsTabs.module.scss'
import { useInlineMemoizedContentGetter, useMemoizedContentGetter } from 'utils/contentful'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { Grid, Box } from '@achieve/sunbeam'
import { Eyebrow } from 'components/Eyebrow'
import { IconListItem } from 'components/IconListItem'
import { BLOCKS } from '@contentful/rich-text-types'
import { ANCHOR_POINTS, AchieveBadge } from 'components/AchieveBadge'
import SlideOnVisible from 'components/SlideOnVisible/SlideOnVisible'
import { StickyTabs } from 'components/StickyTabs'
import {
  SLIDE_ON_VISIBLE_EASING_DEFAULT,
  SLIDE_ON_VISIBLE_DURATION_DEFAULT,
  SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT,
} from 'constants/animation'
import classNames from 'classnames'

function ProductDetailsTabs({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const { title, eyebrow, grid } = useMemoizedContentGetter(content, ['title', 'eyebrow', 'grid'])

  const { jsonContent: { stickyTabsLabels = [] } = {} } = useInlineMemoizedContentGetter(
    content?.fields?.additionalConfiguration,
    ['jsonContent']
  )

  const makeTabObject = (item, index) => {
    return {
      tabLabel:
        stickyTabsLabels[index] ?? item?.fields?.cardTitleText?.content?.[0]?.content?.[0].value,
      tabItem: makeSection(item, index),
    }
  }

  const makeSection = (item, index) => {
    const {
      eyebrow,
      cardTitleText,
      cardSubtitleText,
      cardText,
      iconCardItem,
      cardLink,
      secondaryCardLink,
      cardImage,
      cardImageResponsive,
      cardImageAlternative,
      cardBadgeImage,
      cardJson,
    } = item?.fields || {}
    const badgeProps = {
      width: isMobile ? 70 : 80,
      height: isMobile ? 70 : 80,
      anchor: ANCHOR_POINTS.RIGHT_TOP,
      translateX: -90,
      translateY: 25,
      ...(isMobile ? cardJson?.achieveBadge : cardJson?.achieveBadgeLg || {}),
    }
    return (
      <Grid
        container
        key={`product-card-${index}`}
        className={classNames(
          index % 2 == 0 ? styles['pd-item'] : styles['pd-item-secondary'],
          styles[`backgroud-${cardJson?.backgroud}`] ?? '',
          (!cardImageAlternative && styles['pd-item-no-secondary-image']) ?? ''
        )}
        direction={isMobile ? 'row' : index % 2 != 0 ? 'row-reverse' : 'row'}
        data-testid="pd-section"
      >
        <Grid item xxs={12} lg={6} className={styles['pd-item-panel-left']}>
          <Box className={styles['pd-item-brand']}>
            <SlideOnVisible
              duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
              timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
              easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
            >
              <div>
                <MediaImageStandard
                  content={isMobile ? cardImageResponsive : cardImage}
                  className={styles['pd-item-image']}
                />
              </div>
            </SlideOnVisible>
            {cardImageAlternative && (
              <Box className={styles['pd-item-card-image']}>
                {cardBadgeImage && (
                  <SlideOnVisible
                    duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                    timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
                    easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                  >
                    <div>
                      <AchieveBadge content={cardBadgeImage} {...badgeProps} />
                    </div>
                  </SlideOnVisible>
                )}
                <SlideOnVisible
                  duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                  timeout={350}
                  easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                >
                  <div>
                    <MediaImageStandard
                      content={cardImageAlternative}
                      className={styles['pd-item-subimage']}
                    />
                  </div>
                </SlideOnVisible>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xxs={12} lg={6} className={styles['pd-item-panel-right']}>
          <SlideOnVisible
            duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
            timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
            easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
          >
            <Box className={styles['pd-item-info']}>
              {eyebrow && !isMobile && (
                <Eyebrow
                  className={styles['pd-item-eyebrow']}
                  content={eyebrow}
                  variant="displayXS30"
                  fontWeight="bold"
                  component="h3"
                  black
                  data-testid="pd-item-eyebrow"
                />
              )}
              {cardTitleText && (
                <Typography
                  className={styles['pd-item-title']}
                  content={cardTitleText}
                  variant={isMobile ? 'displayS10' : 'displayS20'}
                  fontWeight="medium"
                  data-testid="pd-item-title"
                />
              )}
              {cardSubtitleText && (
                <Typography
                  content={cardSubtitleText}
                  variant="displayXS30"
                  className={styles['pd-item-subtitle']}
                  fontWeight="regular"
                  data-testid="pd-item-subtitle"
                />
              )}
              {cardText && (
                <Typography
                  content={cardText}
                  variant="displayXS30"
                  className={styles['pd-item-vard-text']}
                  fontWeight="regular"
                />
              )}
              {iconCardItem?.length > 0 && (
                <ul className={styles['pd-item-list']}>
                  {iconCardItem.map((icon, index) => {
                    return (
                      <IconListItem
                        key={`icon-list-item-${index}`}
                        iconColor={icon.fields.iconColor}
                        iconName={icon.fields.iconName}
                        itemText={icon.fields.description}
                        classNameText={styles['pd-item-list-text']}
                        variant="displayXS30"
                        iconSize={20}
                      />
                    )
                  })}
                </ul>
              )}
              {secondaryCardLink && (
                <Box>
                  <LinkButton
                    track={{
                      list_name: 'PRODUCT DETAILS',
                      click_id: secondaryCardLink?.fields?.linkText?.content[0]?.content[0]?.value,
                      click_text:
                        secondaryCardLink?.fields?.linkText?.content[0]?.content[0]?.value,
                      click_type: 'Button Click',
                      click_url: secondaryCardLink?.fields?.linkHref,
                    }}
                    content={secondaryCardLink}
                    typographicOptions={{
                      variantOverride: { [BLOCKS.PARAGRAPH]: 'displayS10' },
                      fontWeight: 'bold',
                    }}
                    color="primary"
                    className={styles['button']}
                    variant="contained"
                    fullWidth={isMobile}
                    data-testid="pd-item-secondary-cta"
                  />
                </Box>
              )}
              {cardLink && (
                <Box>
                  <LinkButton
                    track={{
                      list_name: 'PRODUCT DETAILS',
                      click_id: cardLink?.fields?.linkText?.content[0]?.content[0]?.value,
                      click_text: cardLink?.fields?.linkText?.content[0]?.content[0]?.value,
                      click_type: 'Link Click',
                      click_url: secondaryCardLink?.fields?.linkHref,
                    }}
                    content={cardLink}
                    typographicOptions={{
                      variantOverride: { [BLOCKS.PARAGRAPH]: 'displayS10' },
                      fontWeight: 'bold',
                    }}
                    color="primary"
                    className={styles['button']}
                    variant="text"
                    fullWidth={isMobile}
                    data-testid="pd-item-primary-cta"
                  />
                </Box>
              )}
            </Box>
          </SlideOnVisible>
        </Grid>
      </Grid>
    )
  }

  return (
    <Section
      className={classNames(
        styles['section'],
        styles[`backgroud-${grid?.gridItems?.[0]?.fields?.cardJson?.backgroud}`] ?? ''
      )}
      contain={false}
    >
      <SectionContainer>
        <Typography
          className={styles['pd-eye-brow']}
          fontWeight="medium"
          textAlign="center"
          variant="displayXS30"
          content={eyebrow?.textContent}
        />
      </SectionContainer>
      <SectionContainer>
        <Typography
          className={styles['pd-title']}
          fontWeight="medium"
          textAlign="center"
          variant={isMobile ? 'displayS20' : 'displayM20'}
          content={title?.textContent}
          data-testid="pd-title"
        />
      </SectionContainer>
      {grid?.gridItems && (
        <Box>
          {!isMobile ? (
            <>{grid?.gridItems?.map((item, index) => makeSection(item, index))}</>
          ) : (
            <StickyTabs
              className={styles['sticky-tabs']}
              isMobile={true}
              stickyTabsContent={grid?.gridItems?.map((item, index) => makeTabObject(item, index))}
            />
          )}
        </Box>
      )}
    </Section>
  )
}
export { ProductDetailsTabs }
export default ProductDetailsTabs
